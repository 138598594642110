import {graphql, StaticQuery} from 'gatsby'
import React from 'react'

import {imageUrlFor} from '../../lib/image-url'
import {buildImageObj} from '../../lib/helpers'

import Icon from '../Icon'

import styles from './Footer.module.css'

const query = graphql`
  query FooterQuery {
    section: sanityFooter(_id: {regex: "/(drafts.|)/"}) {
      footerText,
      footerLogo {
        asset {
          _id
        }
        alt,
      },
      footerImage {
        asset {
          _id
        }
        alt,
      }
    }
  }
`

const replaceCounter = (text = '') => {
  const date1 = new Date()
  const date2 = new Date('09/02/2023')

  // To calculate the time difference of two dates
  const differenceInTime = date2.getTime() - date1.getTime()

  // To calculate the no. of days between two dates
  var differenceInDays = differenceInTime / (1000 * 3600 * 24)

  if (text) {
    return text.replace(new RegExp('##counter##', 'g'), Math.ceil(differenceInDays))
  }
}

const date1 = new Date()
const date2 = new Date('09/02/2023')

// To calculate the time difference of two dates
const differenceInTime = date2.getTime() - date1.getTime()

// To calculate the no. of days between two dates
const differenceInDays = differenceInTime / (1000 * 3600 * 24)

const Footer = () => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const {
          section: {
            footerText = '',
            footerLogo,
            footerImage = {}
          }
        } = data

        return (
          <div className={styles.footerContainer}>
            {/* <Icon className={styles.footerLogo} image={footerLogo} /> */}
            <p className={styles.footerText}>
              {`Do svatby zbývá ${Math.ceil(differenceInDays)} dní!`}
            </p>
            <p className={styles.footerText}>{replaceCounter(footerText)}</p>
            {footerImage && footerImage.asset && <img
              className={styles.footerImage}
              src={imageUrlFor(buildImageObj(footerImage))
                .width(1280)
                .url()}
              alt={footerImage.alt}
            />}
          </div>
        )
      }}
    />
  )
}

export default Footer
