import React from 'react'
import {Link} from 'gatsby'

import styles from './WeddingDate.module.css'

const WeddingDate = ({
  weddingDate = ''
}) => {
  const dateArray = weddingDate.split('-')
  const [
    fullYear,
    month,
    day
  ] = dateArray
  return (
    <Link className={styles.link} to='/'>
      <div className={styles.dateWrapper}>
        <div>{day}</div>
        <div>{month}</div>
        <div>{fullYear && fullYear.slice(-2)}</div>
      </div>
    </Link>
  )
}

export default WeddingDate
