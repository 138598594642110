import React from 'react'

import {imageUrlFor} from '../lib/image-url'
import {buildImageObj} from '../lib/helpers'

const Icon = (props) => {
  const {
    image,
    className
  } = props
  return (
    image && image.asset && <img
      className={className}
      src={imageUrlFor(buildImageObj(image))
        .width(128)
        .height(128)
        .url()}
      alt={image.alt}
    /> || <div/>
  )
}

export default Icon
